.page {
    margin: 40px auto 20px;
    padding: 0 var(--content-padding) 50px var(--content-padding);
    max-width: 800px;

    h2 {
        margin: 25px auto 10px;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 40px;
    }

    &__rating {
        margin: 3px 0 0 15px;
        font-size: 13px;
        color: #888;
    }

    img {
        margin: auto;
        max-width: 100%;
    }

    .image-right {
        line-height: 1.2;
        img {
            display: block;
            margin: auto;
            max-width: 420px;
        }
        p, &__annotation {
            text-align: center;
            font-size: 14px;
            color: #666;
        }
    }

    .align-center {
        text-align: center;
    }

    blockquote {
        margin: 0;
        font-family: var(--font-family-monospace);
        background: #fff;
        padding: 10px 30px;
        border: 1px dotted #000;
        border-left: 3px #000 solid;

        & > p:first-child {
            margin: 0;
        }

        & > blockquote {
            margin: 15px 0;
        }
    }

    .footnotes-sep {
        display: none;
    }
    .footnotes {
        margin-top: 40px;
        font-size: 14px;
        color: #666;
    }

    .colored-text {
        color: #f44336;

        &_blue {
            color: #2196f3;
        }

        &_steelblue, &_SteelBlue {
            color: steelblue;
        }
        &_sienna {
            color: sienna;
        }

        &_green {
            color: #4CAF50;
        }
    }

    .aligned-text {
        &_center {
            text-align: center;
        }
        &_right {
            text-align: right;
        }
    }

    // pure.css
    table {
        border-collapse: collapse;
        border-spacing: 0;
        empty-cells: show;
        border: 1px solid #cbcbcb;

        th {
            border-left: 1px solid #cbcbcb;/*  inner column border */
            border-width: 1px;
            font-size: inherit;
            margin: 0;
            overflow: visible; /*to make ths where the title is really long work*/
            padding: 0.5em 1em; /* cell padding */
        }

        td:first-child, th:first-child {
            border-left-width: 1;
        }

        td {
            border: 1px solid #cbcbcb;
            padding: 10px;
        }

        thead {
            background-color: #e0e0e0;
            color: #000;
            text-align: left;
            vertical-align: bottom;
        }
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .page {
        font-size: 17px;

        img {
            max-width: 100vw;
            margin: auto -20px auto -20px;
        }

        .image-right {
            img {
                display: inline-block;
            }

            line-height: 1.2;
            text-align: center;
            p, &__annotation {
                text-align: center;
                font-size: 14px;
                color: #666;
            }
        }
    }
    .page blockquote {
        margin: auto -20px;
        padding: 5px 20px;
    }
}
