
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
  line-height: 1.5;

  --font-family-monospace: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

code {
  font-family: var(--font-family-monospace);
}

.app {
  --content-padding: 20px;

  a {
    text-decoration: none;
    color: #3f51b5;

    &:hover {
      color: #f44336;
    }
  }
}
