
.tabs {
    outline: 2px solid #ddd;
    padding: 15px;

    &__titles {
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 10px;
        display: inline-block;
        border-bottom: 2px solid #ddd;
        transition: border-color 100ms ease-out;
        user-select: none;
        &:not(:last-child) {
            margin-right: 10px;
        }
        &:hover {
            cursor: pointer;
            border-color: #888;
        }
        &_active {
            &, &:hover {
                border-color: #222;
                cursor: normal;
            }
        }
    }
}

.tab {
    display: none;

    &_active {
        display: block;
    }
}
