
.footer {
    margin: 0 auto;
    padding: 0 var(--content-padding);
    max-width: 800px;
    margin-bottom: 30px;
    font-size: 13px;
    text-align: center;
    color: #999;
    a, a:visited {
        color: #99b;
    }
}
