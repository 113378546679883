
.collapsible {
    &__open, &__close {
        color: #3f51b5;
        user-select: none;
        &:hover {
            cursor: pointer;
        }
    }

    &__close {
        display: none;
    }

    &__content {
        visibility: hidden;
        overflow: hidden;
        height: 0;
        opacity: 0;
        transition: opacity 300ms ease;
    }

    &_opened &__open {
        display: none;
    }

    &_opened &__close {
        display: block;
    }

    &_opened &__content {
        visibility: visible;
        height: auto;
        opacity: 1;
        margin: 15px auto;
    }
}
