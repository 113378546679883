
.main-page {
    background: #222;
    height: calc(100vh - 40px - 35px + 1px);
    color: #fff;

    &__content {
        max-width: 600px;
        padding: 10vh 20px 20px 20px;
        margin: auto;
    }

    a {
        color: #ff9800;
        &:hover {
            color: #ffbf61;
        }
    }

    &__title {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin: auto auto 20px auto;
    }

    &__special-link-block {
        text-align: center;
        a {
            background: #ff9800;
            color: #000;
            padding: 4px 12px;
            border-radius: 4px;

            &:hover {
                color: #000;
                background: #ffbf61;
            }
        }
    }
}
